import { Component } from "react";
import { Waypoint } from "react-waypoint";

class InView extends Component {
  setInView() {
    this.setState({ inView: true });
  }

  setOutOfView() {
    this.setState({ inView: false });
  }

  constructor(props) {
    super(props);
    this.state = {
      inView: false
    };

    this.setInView = this.setInView.bind(this);
    this.setOutOfView = this.setOutOfView.bind(this);
  }

  render() {
    const { render } = this.props;
    const { inView } = this.state;

    return (
      <Waypoint
        onEnter={this.setInView}
        topOffset={"0%"}
        bottomOffset={"45%"}
        onLeave={this.setOutOfView}
      >
        <div>{render(inView)}</div>
      </Waypoint>
    );
  }
}

export default InView;
