import s from './style.module.css';
import Container from 'components/Container';

const Apply = ({ title, children }) => (
	<div className={s.apply}>
		<Container>
			<h2 className={s.title}>{title}</h2>
			{children}
		</Container>
	</div>
);

export default Apply;
