import React from "react";
import Grid from "components/Grid";
import Intro from "components/Intro";
import Lede from "components/Lede";
import Section from "components/Section";
import Apply from "components/Apply";
import WorkShowcase from "components/WorkShowcase";
import TotalJobs from "components/TotalJobs";
import GlobalHealthCheck from "components/GlobalHealthCheck";
import MakingOfMeAndYou from "components/MakingOfMeAndYou";
import InView from "components/InView";
import Meta from "components/Meta";

// import s from "./style.module.css";

const Job = ({
  meta,
  intro,
  role,
  entails,
  qualities,
  benefits,
  title,
  apply
}) => {
  return (
    <>
      <Meta {...meta} />

      <Intro>We're hiring</Intro>

      <Lede>
        {intro.map((content, i) => (
          <p key={i} dangerouslySetInnerHTML={{ __html: content }} />
        ))}
      </Lede>

      {role && (
        <Grid
          isEqual
          offset={
            <WorkShowcase
              caption={"See one of our projects for Totaljobs"}
              url="https://beyondwordsstudio.com/our-work/how-love-works/"
            >
              <InView render={inView => <TotalJobs inView={inView} />} />
            </WorkShowcase>
          }
        >
          <Section title="The role" itemsAsParagraphs items={role} />
        </Grid>
      )}

      {entails && (
        <Grid
          isEqual
          after={
            <WorkShowcase
              caption="See one of our projects for the BBC"
              url="https://beyondwordsstudio.com/our-work/the-making-of-me-you/"
              bg="blue"
            >
              <InView render={inView => <MakingOfMeAndYou inView={inView} />} />
            </WorkShowcase>
          }
        >
          <Section title="The right candidate will:" items={entails} />
        </Grid>
      )}

      {qualities && (
        <Grid
          isEqual
          offset={
            <WorkShowcase
              caption={
                "See one of our projects for the Bill & Melinda Gates Foundation"
              }
              url="https://beyondwordsstudio.com/our-work/global-health-check/"
              bg="cream"
            >
              <InView
                render={inView => <GlobalHealthCheck inView={inView} />}
              />
            </WorkShowcase>
          }
        >
          <Section title="Skills and qualities we’re after" items={qualities} />
        </Grid>
      )}

      {benefits && (
        <Grid full>
          <Section gridded title="Details" items={benefits} />
        </Grid>
      )}

      {apply && (
        <Apply title="Apply">
          {apply.map(p => (
            <p dangerouslySetInnerHTML={{ __html: p }} />
          ))}
        </Apply>
      )}
    </>
  );
};

export default Job;
