import React from "react";
import Lede from "components/Lede";
import Intro from "components/Intro";
import Grid from "components/Grid";
import Meta from "components/Meta";
// import Container from "components/Container";
import s from "./style.module.css";
import { Link } from "react-router-dom";

const JobList = ({ jobs }) => {
  return (
    <div className={s.wrap}>
      <Meta
        title={`Beyond Words Studio - We're hiring`}
        description={
          "We’re looking for new people to join us here at one of the world’s most forward-thinking data visualization studios."
        }
      />

      <Intro>We're hiring</Intro>

      {jobs.length !== 0 && (
        <>
      <Lede>
        <p>
          We’re looking for new people to join us here at one of the world’s most forward-thinking data visualization studios. If you’re passionate about data storytelling and love creating beautiful things, we might have a role that’s right for you.
              
            </p>
          </Lede>

          <div className={s.jobs}>
            {jobs.map(({ slug, title, overview }) => (
              <Link key={slug} to={`/${slug}`} className={s.job}>
                <h2 className={s.title}>{title}</h2>
                <p className={s.overview}>{overview}</p>
                <span className={s.moreInfo}>More info</span>
              </Link>
            ))}
          </div>

          <Grid isCentered>
            <p>
              We encourage candidates from all backgrounds to apply. Diversity
              and inclusion are important to us and we are committed to making
              long-term meaningful change. A diverse workforce makes us better
              able to innovate, be creative, be better problem solvers and
              provide an inclusive environment for everyone.
            </p>
          </Grid>
        </>
      )}

      {jobs.length === 0 && (
        <Lede>
          <p>
            We’re not hiring for specific roles right now but we’re always
            interested in talking to designers, journalists, developers or
            project managers who are as passionate about data and information
            design as we are.
          </p>

          <p>
            Drop us a line on{" "}
            <a href="mailto:hello@beyondwordsstudio.com">
              hello@beyondwordsstudio.com
            </a>{" "}
            and we’d be happy to talk.
          </p>
        </Lede>
      )}
    </div>
  );
};

export default JobList;
