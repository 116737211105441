import Header from "components/Header";
import Footer from "components/Footer";
import About from "components/About";
import s from "./style.module.css";

const Main = ({ children }) => (
  <div className={s.main}>
    <div className={s.header}>
      <Header />
    </div>
    <main className={s.inner}>
      {children}

      <About title="About Beyond Words Studio">
        <p>
          {" "}
          <a
            href="https://beyondwordsstudio.com/"
            target="_blank"
            rel="noreferrer noopener"
          >
            Beyond Words
          </a>{" "}
          is one of the world’s most forward-thinking data visualization studios. We’re passionate about helping people understand data by creating compelling stories and data visualizations.
        </p>
        <p>
          Based in East London, we’re a multi-disciplinary team of designers,
          journalists, analysts and developers. We create{" "}
          <a
            href="https://beyondwordsstudio.com/our-work/"
            target="_blank"
            rel="noreferrer noopener"
          >
            data-driven visual stories
          </a>{" "}
          and experiences exploring vital topics as varied as health,
          technology and culture. We do this for world-changing
          clients such as the World Health Organization, Netflix, the Bill & Melinda Gates Foundation, Visa and the World Bank.
        </p>
      </About>
    </main>
    <div className={s.footer}>
      <Footer />
    </div>
  </div>
);

export default Main;
