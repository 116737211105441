import s from './style.module.css';
import Container from 'components/Container';

const Lede = ({ children }) => (
	<div className={s.lede}>
		<Container>{children}</Container>
	</div>
);

export default Lede;
