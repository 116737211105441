import s from './style.module.css';
import cn from 'classnames';

const Grid = ({
	children,
	offset,
	after,
	isEqual,
	isCentered,
	full,
	hideFlourish,
	title
}) => (
	<div className={s.wrap}>
		<div
			className={cn(
				s.grid,
				{ [s.isEqual]: isEqual },
				{ [s.isCentered]: isCentered },
				{ [s.full]: full },
				{ [s.hideFlourish]: hideFlourish }
			)}
		>
			{title && <h2 className={s.title}>{title}</h2>}
			{offset && <div className={s.offset}>{offset}</div>}
			<div className={s.column}>{children}</div>
			{after && <div className={s.after}>{after}</div>}
		</div>
	</div>
);

export default Grid;
