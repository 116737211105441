import s from "./style.module.css";

const Footer = () => (
  <div className={s.root}>
    <p>
      <a
        href="https://beyondwordsstudio.com/"
        title="Beyond Words Studio"
        target="_blank"
        rel="noreferrer noopener"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="200" height="14.96">
          <title>Beyond Words Studio</title>
          <g id="wrapper">
            <path
              fill="#fafafa"
              d="M4.94.21a4.52 4.52 0 0 1 3.26 1 3.88 3.88 0 0 1 1.36 2.94 3.8 3.8 0 0 1-.93 2.43 4.16 4.16 0 0 1 2.17 3.72 4.39 4.39 0 0 1-1.33 3.14 5 5 0 0 1-3.8 1.31H0V.21zM2.8 5.82h2a1.89 1.89 0 0 0 1.54-.52 1.51 1.51 0 0 0 0-2.08 1.89 1.89 0 0 0-1.56-.52h-2zm2.8 6.44a2.31 2.31 0 0 0 1.87-.64A2 2 0 0 0 7.48 9a2.31 2.31 0 0 0-1.87-.64H2.8v3.95zM19.11 2.7v3h4.47v2.51h-4.47v4.05h6.13v2.49h-8.93V.21H25V2.7zM37.71 8.16v6.59H34.9V8.16l-5-8h3.2l3.2 5.11L39.49.21h3.2zM53.5 0a7.34 7.34 0 0 1 7.58 7.48 7.58 7.58 0 0 1-15.16 0A7.34 7.34 0 0 1 53.5 0zm0 12.46a4.83 4.83 0 0 0 4.78-5 4.78 4.78 0 1 0-9.56 0 4.83 4.83 0 0 0 4.78 5zM66.67.21h2l8.1 9.47V.21h2.8v14.54h-2l-8.1-9.47v9.47h-2.8zM90.77.21a8 8 0 0 1 5 1.37 7.3 7.3 0 0 1 2.89 5.9 7.3 7.3 0 0 1-2.89 5.9 8 8 0 0 1-5 1.37H86.2V.21zm4 4.11a4.44 4.44 0 0 0-3.69-1.62H89v9.56h2.08a4.44 4.44 0 0 0 3.72-1.62 5.07 5.07 0 0 0 1.06-3.16 5.07 5.07 0 0 0-1.06-3.16zM111.13.21H114l3 9.08 3.2-9.08h2l3.2 9.08 3-9.08h2.83l-4.82 14.54h-2l-3.24-9.2-3.22 9.2h-2zM142.81 0a7.34 7.34 0 0 1 7.58 7.48 7.58 7.58 0 0 1-15.16 0A7.34 7.34 0 0 1 142.81 0zm0 12.46a4.83 4.83 0 0 0 4.78-5 4.78 4.78 0 1 0-9.56 0 4.83 4.83 0 0 0 4.78 5zM158.78 8.31v6.44H156V.21h5.3a4.4 4.4 0 0 1 3.18 1.08 4.07 4.07 0 0 1 0 5.94 3.75 3.75 0 0 1-1.45.85l5.44 6.67H165l-5.2-6.44zm2.33-2.49a1.72 1.72 0 0 0 1.41-.44 1.69 1.69 0 0 0 0-2.22 1.71 1.71 0 0 0-1.41-.46h-2.33v3.12zM177.77.21a8 8 0 0 1 5 1.37 7.3 7.3 0 0 1 2.89 5.9 7.3 7.3 0 0 1-2.89 5.9 8 8 0 0 1-5 1.37h-4.57V.21zm4 4.11a4.44 4.44 0 0 0-3.72-1.62H176v9.56h2.08a4.44 4.44 0 0 0 3.72-1.62 5.07 5.07 0 0 0 1.06-3.16 5.07 5.07 0 0 0-1.06-3.16zM191.65 10.86a4.78 4.78 0 0 0 3.43 1.6 1.91 1.91 0 0 0 2.12-1.83c0-1.87-2.7-2.1-4.65-3.24a3.67 3.67 0 0 1-2.06-3.3c0-2.6 2.39-4.09 4.8-4.09a6.73 6.73 0 0 1 4.22 1.37L198 3.45a3.89 3.89 0 0 0-2.51-1c-1.06 0-2.24.46-2.24 1.58 0 2.35 6.71 1.62 6.71 6.52 0 2.58-2.31 4.36-4.92 4.36a7.76 7.76 0 0 1-5.26-2.18z"
            />
          </g>
        </svg>
      </a>
    </p>
    <p className={s.copyright}>
      <small>Copyright Beyond Words Studio Ltd. 2023</small>
    </p>
  </div>
);

export default Footer;
