import React from "react";
import Intro from "components/Intro";
import Lede from "components/Lede";

const FourZeroFour = () => {
  return (
    <>
      <Intro>404</Intro>

      <Lede>
        <p>No job found or the position may have been filled.</p>
        <p>
          <a href="/">View all current job listings.</a>
        </p>
      </Lede>
    </>
  );
};

export default FourZeroFour;
