import s from './style.module.css';
import cn from 'classnames';

const calcArea = (num, max) =>
	(Math.sqrt((max * max * Math.PI * (num / max)) / Math.PI) / max) * 50;

const sizes = [
	0.01,
	1.3,
	2.5,
	1.1,
	0.03,
	1.8,
	5,
	11.3,
	26.6,
	23.5,
	1.1,
	0.4,
	0.1,
	0.3
];

const MakingOfMeAndYou = ({ inView, max = 30 }) => (
	<div className={cn(s.wrap, { [s.inView]: inView })}>
		<p className={s.text}>How much do my tissues and organs weigh?</p>

		<div className={s.squares}>
			{sizes.map((_, i) => (
				<span
					className={s.square}
					style={{
						width: `${calcArea(_, max)}%`,
						transitionDelay: `${calcArea(_, max) * 0.0075}s`
					}}
				>
					<span className={s.inner} />
				</span>
			))}
		</div>
	</div>
);

export default MakingOfMeAndYou;
