import s from './style.module.css';
import Container from 'components/Container';

const Intro = ({ children }) => (
	<div className={s.intro}>
		<Container>
			<h1 className={s.text}>{children}</h1>
		</Container>
	</div>
);

export default Intro;
