import s from "./style.module.css";
import cn from "classnames";

const Section = ({ title, items = [], gridded, itemsAsParagraphs }) => (
  <div className={s.wrap}>
    {title && <h2>{title}</h2>}
    {!itemsAsParagraphs && (
      <ul className={cn(s.items, { [s.gridded]: gridded })}>
        {items.map(item => (
          <li key={item} className={s.item}>
            {item}
          </li>
        ))}
      </ul>
    )}

    {itemsAsParagraphs && (
      <div className={cn(s.items, s.isParagraphs, { [s.gridded]: gridded })}>
        {items.map(item => (
          <p
            key={item}
            className={s.item}
            dangerouslySetInnerHTML={{
              __html: item
            }}
          />
        ))}
      </div>
    )}
  </div>
);

export default Section;
