import s from "./style.module.css";
import Grid from "components/Grid";

const About = ({ title, children, link }) => (
  <div className={s.about}>
    <Grid isCentered hideFlourish>
      <h2 className={s.title}>{title}</h2>
      {children}
    </Grid>
  </div>
);

export default About;
