import s from './style.module.css';
import cn from 'classnames';

const WorkShowcase = ({ children, caption, bg, url }) => (
	<div className={s.wrap}>
		<div
			className={cn(s.bg, {
				[s.bgBlue]: bg === 'blue',
				[s.bgCream]: bg === 'cream'
			})}
		>
			{children}
		</div>
		<p className={s.caption}>
			<a
				href={url + ''}
				target="_blank"
				rel="noreferrer noopener"
			>
				{caption}
			</a>
		</p>
	</div>
);

export default WorkShowcase;
