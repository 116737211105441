import s from './style.module.css';
import cn from 'classnames';

const data = [6, 10, 17, 6, 37, 5, 8, 6, 20, 6];

const colors = [
	'#FF7468',
	'#805273',
	'#00C6B7',
	'#5D449F',
	'#E082C3',
	'#00BAF2',
	'#FDA0A3',
	'#008BB9',
	'#A895A3',
	'#023B70'
];

const allColors = data.reduce((arr, next, i) => {
	arr.push(...Array(next).fill(colors[i]));
	return arr;
}, []);

const GlobalHealthCheck = ({ inView }) => (
	<div className={cn(s.wrap, { [s.inView]: inView })}>
		<p className={s.text}>
			<span>Total Healthy days lost that year from diseases shown</span>

			<span className={s.number}>121</span>
		</p>

		<div className={s.dots}>
			{Array(365)
				.fill()
				.map((_, i) => (
					<span
						key={i}
						style={{
							color: allColors[i]
						}}
						className={s.dot}
					>
						{i < 121 && (
							<span
								className={s.dotInner}
								style={{
									transitionDelay: `${0.005 * i}s`
								}}
							/>
						)}
					</span>
				))}
		</div>
	</div>
);

export default GlobalHealthCheck;
