import { useEffect } from "react";
import { Router, Switch, Route, useLocation } from "react-router-dom";
import { createBrowserHistory } from "history";

import Main from "layouts/main";
import JobList from "pages/JobList";
import Job from "pages/Job";
import FourZeroFour from "pages/FourZeroFour";

import jobs from "./jobs/index.json";

const history = createBrowserHistory();
history.listen((location, action) => {
  if (window.ga) {
    setTimeout(() => {
      console.log(document.title);
      window.ga("set", "page", location.pathname);
      window.ga("set", "title", document.title);
      window.ga("set", "location", window.location.href);
      window.ga("send", "pageview");
    }, 200);
  }
});

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  return (
    <div className="App">
      <Router history={history}>
        <ScrollToTop />
        <Main>
          <Switch>
            {jobs.map(job => (
              <Route key={job.slug} path={`/${job.slug}`}>
                <Job {...job} />
              </Route>
            ))}
            <Route exact path="/">
              <JobList jobs={jobs} />
            </Route>

            <Route path="*">
              <FourZeroFour />
            </Route>
          </Switch>
        </Main>
      </Router>
    </div>
  );
}

export default App;
